@import '../abgov-core-colors.scss';
@import './page-margins.scss';
@import './responsive-breakpoints.scss';

@mixin page-template(
  $page-margin: 150px,
  $tablet-page-margin: 72px,
  $mobile-page-margin: 24px,
  $content-margin: auto,
  $tablet-content-margin: 72px,
  $mobile-content-margin: 24px
) {
  body {
    margin: 0;
    padding: 0;
  }

  /* header **********************************************************/
  //   .goa-header {
  //     @include page-padding($page-margin, $tablet-page-margin, $mobile-page-margin);
  //   }

  /* banner **********************************************************/
  .goa-banner {
    color: $goa-color-text-light;
    background-color: $goa-color-brand;

    @include page-padding($page-margin, $tablet-page-margin, $mobile-page-margin);

    border-bottom: 8px solid #c8eefa;

    h1 {
      padding-top: 64px;
    }

    .descriptor {
      font-size: 24px;
      font-weight: 400;
      line-height: 34px;

      color: $goa-color-text-light;
      padding: 24px 0 64px 0;
    }

    .breadcrumb {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);

      ul {
        padding: 0;
        margin-top: 0;
        margin-bottom: 24px;

        li {
          display: inline-block;
          margin-left: 0;
          font-size: 14px;

          a {
            color: $goa-color-text-light !important;
          }
        }

        li:not(:last-child):after {
          margin: 0 8px;
          content: '🡒';
        }
      }
    }
  }

  /* content ********************************************************/

  .goa-content-section {
    section {
      margin-top: 5em;
    }

    section:last-child {
      margin-bottom: 5em;
    }

    margin-top: 0;
    margin-bottom: 0;
    @include page-margins($content-margin, $tablet-content-margin, $mobile-content-margin);

    max-width: 750px;

    @include tablet {
      max-width: unset;
    }

    @include mobile {
      max-width: unset;
    }
  }

  /* footer ********************************************************/
  .goa-footer {
    background-color: $color-gray-100;
    padding-top: 56px;
    padding-bottom: 56px;
    @include page-padding($page-margin, $tablet-page-margin, $mobile-page-margin);

    border-bottom: 16px solid $goa-color-interactive;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    border-bottom: 16px solid $goa-color-brand;

    .link {
      flex: 1 0 auto;
    }

    .copyright {
      flex: 0 1 auto;
    }
  }
}
