@import '../abgov-core-colors.scss';
/* This style is from the alberta design system */
@mixin goa-input-styles {
  & {
    box-sizing: border-box;
    width: 100%;
    margin: 8px 0 0;
    padding: 12px;
    padding-right: 8px;
    border-radius: 4px;
    border: 1px solid $color-gray-600;
    font-size: 18px;
    font-weight: 400;
    color: $goa-color-interactive;
    /* removes default arrow */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  /* remove default arrow IE */
  &::-ms-expand {
    display: none;
  }

  &:hover {
    border: 1px solid $goa-color-interactive;
  }

  &:focus {
    box-shadow: 0 0 0 3px $goa-color-interactive--focus;
    outline: none;
  }
}
