@import '../abgov-core-colors.scss';
@import './svgs.scss';
@import './page-margins.scss';

.goa-notification {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: 100%;

  &.dismissed {
    display: none;
  }

  @include page-padding;

  color: $goa-color-text-light;
  background: var(--goa-color-interactive-default, #0070c4);

  .content {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    margin: 0;
    position: relative;

    padding: 0 36px;

    .message {
      flex: 1 0 0;

      box-sizing: border-box;
      min-height: 56px;
      padding: 28px 0;
    }

    .close {
      flex: 0 1 auto;
    }
  }

  a {
    color: $goa-color-text-light;

    &:hover,
    &:focus {
      color: $goa-color-text-light;
      text-decoration: none;
    }
  }

  /* variants ***/
  &.goa--event {
    background-color: $goa-color-status-success;
  }

  &.goa--important {
    background-color: $goa-color-status-warning;

    a {
      color: var(--goa-color-text-default, #333333);

      &:hover,
      &:focus {
        color: var(--goa-color-text-default, #333333);
      }
    }
  }

  &.goa--emergency {
    background-color: $goa-color-status-emergency;
  }

  & .close {
    align-self: center;
    position: absolute;
    right: 12px;

    cursor: pointer;

    svg {
      fill: currentColor;
    }
  }

  & .content:before {
    position: absolute;
    top: 50%;
    left: 6px;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    content: '';

    @include information;
  }

  &.goa--emergency .content:before {
    @include error;
  }

  &.goa--important .content:before {
    @include warning;
  }

  &.goa--event .content:before {
    @include calendar;
  }
}
