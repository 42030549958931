mat-sidenav-container {
  --mat-sidenav-content-background-color: var(--goa-color-greyscale-white, #fff);

  mat-list-item {
    --mdc-list-list-item-label-text-weight: normal;
    &.is-active {
      --mdc-list-list-item-label-text-color: $goa-color-text-light;
      --mdc-list-list-item-hover-label-text-color: $goa-color-text-light;
      --mdc-list-list-item-focus-label-text-color: $goa-color-text-light;
    }
  }
}
