@import './responsive-breakpoints.scss';

@mixin page-margins($desktop-margin: 72px, $tablet-margin: 24px, $mobile-margin: 24px) {
  margin-left: $desktop-margin;
  margin-right: $desktop-margin;

  @include tablet {
    margin-left: $tablet-margin;
    margin-right: $tablet-margin;
  }

  @include mobile {
    margin-left: $mobile-margin;
    margin-right: $mobile-margin;
  }
}

@mixin page-padding($desktop-margin: 72px, $tablet-margin: 24px, $mobile-margin: 24px) {
  padding-left: $desktop-margin;
  padding-right: $desktop-margin;

  @include tablet {
    padding-left: $tablet-margin;
    padding-right: $tablet-margin;
  }

  @include mobile {
    padding-left: $mobile-margin;
    padding-right: $mobile-margin;
  }
}
