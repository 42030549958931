//Text
$goa-color-text: var(--goa-color-text-default, #333333);
$goa-color-text-secondary: var(--goa-color-text-secondary, #666666);
$goa-color-text-light: var(--goa-color-text-light, #ffffff);

//Interactive elements (buttons, links, and inputs)
$goa-color-interactive: var(--goa-color-interactive-default, #0070c4);
$goa-color-interactive--hover: var(--goa-color-interactive-hover, #004f84);
$goa-color-interactive--focus: var(--goa-color-interactive-focus, #feba35);
$goa-color-interactive--error: var(--goa-color-interactive-error, #ec040b);

//Brand
$goa-color-brand: var(--goa-color-brand-default, #0081a2);
$goa-color-brand-light: var(-goa-color-brand-light, #c8eefa);
$goa-color-brand-dark: var(--goa-color-brand-dark, #005072);

//Grayscale
$color-white: var(--goa-color-greyscale-white, #ffffff);
$color-gray-100: var(--goa-color-greyscale-100, #f1f1f1);
$color-gray-200: var(--goa-color-greyscale-200, #dcdcdc);
$color-gray-400: var(--goa-color-greyscale-400, #adadad);
$color-gray-600: var(--goa-color-greyscale-600, #666666);
$color-black: var(--goa-color-greyscale-black, #333333);

//Status
$goa-color-status-info: var(--goa-color-info-default, #005daa);
$goa-color-status-info-light: var(--goa-color-info-light, #b4daf8);
$goa-color-status-info-dark: var(--goa-color-info-dark, #00347a);
$goa-color-status-warning: var(--goa-color-warning-default, #feba35);
$goa-color-status-warning-light: var(--goa-color-warning-light, #fcefd0);
$goa-color-status-warning-dark: var(--goa-color-warning-dark, #c68a00);
$goa-color-status-emergency: var(--goa-color-emergency-default, #ec040b);
$goa-color-status-emergency-light: var(--goa-color-emergency-light, #ffcecf);
$goa-color-status-emergency-dark: var(--goa-color-emergency-dark, #b00000);
$goa-color-status-success: var(--goa-color-success-default, #00853f);
$goa-color-status-success-light: var(--goa-color-success-light, #c6e0d0);
$goa-color-status-success-dark: var(--goa-color-success-dark, #005715);

//Shadow
$shadow-0: 0 0 rgba(0, 0, 0, 0);
$shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.24);
$shadow-2: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12);
$shadow-3: 0 10px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);
$shadow-4: 0 15px 25px rgba(0, 0, 0, 0.15), 0 5px 10px rgba(0, 0, 0, 0.05);
$shadow-5: 0 20px 40px rgba(0, 0, 0, 0.2);
