mat-option {
  --mat-option-label-text-size: var(--scss-font-size, 18px);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.12);
}

mat-opgroup,
mat-optgroup {
  --mat-optgroup-label-text-size: var(--scss-font-size, 18px);
  font-size: var(--scss-font-size, 18px);
}

mat-option {
  --mat-option-label-text-color: var(--goa-color-text-default, #333);
  --mat-option-label-text-weight: normal;
  --mat-option-selected-state-label-text-color: var(--goa-color-text-default, #333);
}

mat-opgroup,
mat-optgroup {
  --mat-optgroup-label-text-color: #0000008a;
  --mat-optgroup-label-text-weight: bold;
}

mat-select {
  --mat-select-enabled-trigger-text-color: var(--goa-color-text-default, #333);
  --mat-select-trigger-text-weight: normal;
  --mat-select-trigger-text-size: var(--scss-font-size, 18px);
  --mat-select-trigger-text-line-height: var(--scss-font-size, 18px);
}

.mat-select-search-inner .mat-select-search-input {
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  margin: 0 !important;
}

.mat-mdc-select {
  margin-top: 8px;
  padding: 12px 0;
  border-radius: 4px;
  border: 1px solid var(--goa-color-greyscale-700, #666);
  background-color: var(--goa-color-greyscale-white, #fff);

  &:focus,
  &:active {
    @extend .outline;
  }
  &:hover {
    border-color: var(--goa-color-interactive-default, #0070c4);
  }
  .mat-mdc-select-trigger {
    padding: 0 12px;
    height: 100%;
    .mat-mdc-select-value {
      min-height: 20px;
    }
  }

  &.mat-mdc-select-disabled {
    background-color: rgba(220, 220, 220, 0.3);
    opacity: 1;
    color: var(--goa-color-text-secondary, #666);

    .mat-mdc-select-value-text {
      color: var(--goa-color-greyscale-700, #666) !important;
    }
    &:focus,
    &:active,
    &:hover {
      outline-style: none;
      border-color: var(--goa-color-greyscale-700, #666);
    }
  }
}

.mat-mdc-select-search-clear {
  border: none !important;

  .mat-button-focus-overlay {
    background-color: transparent !important;
  }
}

ngx-mat-select-search .mat-select-search-inner-toggle-all {
  .mat-select-search-toggle-all-checkbox {
    padding-left: 13px;
    padding-right: 8px;
  }
}

//Offset fix for the drop down list overlay so it doesn't appear over the drop down list
.cdk-overlay-container {
  .cdk-overlay-pane {
    margin-top: var(--scss-dropdown-offset, 10px);
    margin-bottom: var(--scss-dropdown-offset, 10px);
  }
}

//There is more things we could include here (see overrides from apps).  But I'm not confident the intake apps have the same styling as the case apps
