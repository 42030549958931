mat-button-toggle {
  //fonts
  --mat-standard-button-toggle-selected-state-background-color: var(--goa-color-greyscale-white, #fff);
  --mat-legacy-button-toggle-selected-state-background-color: var(--goa-color-greyscale-white, #fff);
  --mat-standard-button-toggle-selected-state-text-color: var(--goa-color-interactive-default, #0070c4);
  --mat-legacy-button-toggle-selected-state-text-color: var(--goa-color-interactive-default, #0070c4);

  //borders
  --mat-standard-button-toggle-divider-color: var(--goa-color-greyscale-200, #dcdcdc);
  font-size: var(--scss-font-size, 18px) !important;
}

//mat toggle colors
mat-button-toggle-group {
  border-color: var(--goa-color-interactive-default, #0070c4) !important;
  margin-top: 10px;

  //Diable the checkmark globally.
  //Otherwise we need to add the property [hideSingleSelectionIndicator]="true" on mat-button-group
  .mat-pseudo-checkbox {
    display: none;
  }

  .mat-button-toggle {
    border-color: var(--goa-color-interactive-default, #0070c4) !important;

    button {
      background-color: var(--goa-color-greyscale-white, #fff);
      color: var(--goa-color-interactive-default, #0070c4);
      font-weight: var(--button-font-weight, 400);
    }
  }

  .mat-button-toggle.mat-button-toggle-checked {
    button {
      background-color: var(--goa-color-interactive-default, #0070c4);
      color: var(--goa-color-greyscale-white, #fff);
    }
  }
}

//remove borders as there are error styles that are setting the error state
mat-button-toggle-group.ng-invalid.ng-touched {
  border: none;
  .mat-button-toggle {
    border: none;
  }
}

//disable the whole button group
mat-button-toggle-group[aria-disabled='true'] {
  opacity: 0.5;
  cursor: not-allowed;
}

//disable an individual button when the whole group isn't disabled
mat-button-toggle-group[aria-disabled='false'] mat-button-toggle.mat-button-toggle-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
