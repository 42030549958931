.mat-mdc-tab-header {
  //override font size in header, we always want it the default here, not getting the 18 from typography settings
  --scss-font-size: 18px;
}

mat-tab-header [role='tab'],
nav [role='tab'] {
  --mat-tab-header-inactive-focus-label-text-color: var(--goa-color-greyscale-black, #333);
  --mat-tab-header-inactive-hover-label-text-color: var(--goa-color-greyscale-black, #333);
  --mat-tab-header-active-focus-label-text-color: var(--goa-color-greyscale-black, #333);
  --mat-tab-header-active-hover-label-text-color: var(--goa-color-greyscale-black, #333);
  --mat-tab-header-active-label-text-color: var(--goa-color-greyscale-black, #333);
  --mat-tab-header-inactive-label-text-color: var(--goa-color-greyscale-black, #333);

  //--mdc-tab-indicator-active-indicator-height: 4px;
  &.mdc-tab--active {
    & * {
      font-weight: bolder;
    }

    .mdc-tab__content {
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 4px;
        background: var(--goa-color-interactive-default, #0070c4);
        right: 0;
        bottom: 0;
      }
    }
  }

  .mdc-tab-indicator {
    display: none !important;
  }
}

mat-tab-header,
nav {
  .mat-mdc-tab-label-container,
  .mat-mdc-tab-link-container {
    border-bottom: 1px solid var(--goa-color-greyscale-200, #dcdcdc);
  }

  a:focus {
    outline-style: none;
  }
}
