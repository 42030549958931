/*Terence: styles for expansion panels
- here are a bit of custom markup updates to match mockups
- markup for panel is in case-management (client-dependents.component.html)*/

/*colors for the expansion panel*/
$ads-mat-expansion-panel-header-color: #f1f1f1;
$ads-mat-expansion-panel-title-color: rgba(0, 0, 0, 0.54);
$ads-mat-expansion-panel-content-header-color: grey;
$ads-mat-expansion-panel-border-color: black;
$ads-mat-expansion-panel-content-color: black;

mat-accordion.expansion-panel {
  display: inline-block;
  margin: 10px 20px 10px 20px;

  mat-expansion-panel {
    border: 1px solid $ads-mat-expansion-panel-title-color;
  }
  mat-expansion-panel.mat-expanded {
    border: 1px solid $ads-mat-expansion-panel-border-color;
  }
  mat-expansion-panel {
    mat-expansion-panel-header {
      height: auto;
      min-height: auto;
      background-color: $ads-mat-expansion-panel-header-color;
      padding: 10px 24px;
      font-weight: normal;

      mat-panel-title {
        flex-grow: 0;
        width: auto;
        margin-right: 30px;
        // color: $ads-mat-expansion-panel-title-color;
      }
      mat-panel-description {
        mat-icon {
          position: absolute;
          right: 0;
          margin-right: 5px;
          margin-top: 2px;
        }
      }
    }
    mat-expansion-panel-header.mat-expanded:hover,
    mat-expansion-panel-header.mat-expanded {
      border: 1px;
      background-color: $ads-mat-expansion-panel-header-color;
      mat-panel-description {
        font-weight: bold;
      }
    }
  }

  // content area
  .panel-header {
    color: $ads-mat-expansion-panel-content-header-color;
    font-size: 14px;
  }
  .panel-column {
    width: 200px;
    display: inline-block;
  }

  .panel-group {
    width: 200px;
    display: inline-block;
    vertical-align: top;
    .panel-header {
      color: $ads-mat-expansion-panel-content-header-color;
      font-size: 14px;
    }
    .panel-data {
      color: $ads-mat-expansion-panel-content-color;
      font-size: 14px;
    }
  }
}
