mat-list-option.mat-mdc-list-item,
.mat-mdc-list-option {
  --mdc-list-list-item-label-text-weight: normal;
}

mat-selection-list {
  mat-list-option {
    &.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
      margin-left: 0;
      margin-right: 12px;
    }
  }
}
