.mat-mdc-menu-panel {
  min-height: unset !important;
  border-radius: 0 0 0 4px !important;

  --mat-menu-item-label-text-weight: normal;
  --mat-menu-item-label-text-size: var(--scss-font-size, 18px);
  .mat-mdc-menu-content {
    padding: 0 !important;
  }
}
