.mat-mdc-radio-button {
  --mat-radio-checked-ripple-color: transparent !important;
  --mat-radio-ripple-color: transparent !important;
}
.mat-mdc-radio-group {
  display: flex;
  flex-direction: column;
}
.cdk-focused .mdc-radio__background {
  @extend .outline;
}

mat-radio-button {
  .mdc-form-field {
    //we are changing the radio to be aligned with the top of the label instead of the middle
    align-items: flex-start;
  }
}

mat-radio-button.mat-mdc-radio-checked
  .mdc-radio__native-control:checked
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  transform: scale(0.7);
}

mat-radio-button.mat-mdc-radio-button {
  .mdc-radio {
    --mdc-radio-state-layer-size: 28px;
    padding-left: 0;
  }
}
