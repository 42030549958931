mat-dialog-container.mat-mdc-dialog-container {
  .mdc-dialog__surface {
    padding: 24px;
  }
  .mdc-dialog__title::before {
    height: 0;
  }

  .mdc-dialog__surface,
  .mdc-dialog__title {
    --mdc-dialog-subhead-font: acumin-pro-semi-condensed, sans-serif;
    --mdc-dialog-subhead-line-height: 24px;
    --mdc-dialog-subhead-size: 24px;
    --mdc-dialog-subhead-weight: normal;
    --mdc-dialog-subhead-tracking: normal;
    --mdc-dialog-supporting-text-font: acumin-pro-semi-condensed, sans-serif;
    --mdc-dialog-supporting-text-line-height: 28px;
    --mdc-dialog-supporting-text-size: 16px;
    --mdc-dialog-supporting-text-weight: normal;
    --mdc-dialog-supporting-text-tracking: normal;

    --mdc-dialog-container-color: white;
    --mdc-dialog-subhead-color: var(--goa-color-text-default, #333333);
    --mdc-dialog-supporting-text-color: var(--goa-color-text-default, #333333);
  }

  .mat-mdc-dialog-actions {
    justify-content: end;
    padding-right: 24px;
  }
}
