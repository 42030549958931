.mdc-button {
  color: var(--goa-color-interactive-default, #0070c4);
  border: 1px solid var(--goa-color-greyscale-200, #dcdcdc);
  &:hover,
  &:focus,
  &:active {
    color: var(--goa-color-greyscale-black, #333);
    border-color: var(--goa-color-greyscale-black, #333);
  }
  &:focus,
  &:active {
    @extend .outline;
  }
  &[disabled] {
    @extend .ads-disabled;
  }
}

.ads-secondary {
  border: 2px solid var(--goa-color-interactive-default, #0070c4);
  @extend .ads-tertiary;
}

.mat-mdc-stroked-button:not(.mat-button-disabled) {
  border-color: var(--goa-color-interactive-default, #0070c4) !important;
}

.mat-mdc-raised-button,
.mat-mdc-flat-button,
.mat-mdc-unelevated-button,
.mat-mdc-fab,
.mat-mdc-stroked-button {
  &.mat-primary {
    @extend .ads-primary;
    &:not(:disabled) {
      --mdc-filled-button-label-text-color: var(--goa-color-greyscale-white, #fff);
    }
  }
  &.mat-secondary {
    @extend .ads-secondary;
  }
  &.mat-mdc-button-base {
    @extend .ads-tertiary;
  }
}

button.mdc-button {
  box-shadow: none !important;
  font-weight: var(--button-font-weight, 400) !important;
}

.link-button {
  cursor: pointer;
  text-decoration: underline;
  font-size: 18px;
}
