.mat-mdc-checkbox,
.mdc-checkbox {
  --mdc-checkbox-selected-checkmark-color: var(--goa-color-text-light, #fff);
  // --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  // --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  // --mdc-checkbox-selected-checkmark-color: #000;
  //--mdc-checkbox-selected-focus-icon-color: var(--goa-color-interactive-focus, #feba35);
  // --mdc-checkbox-selected-hover-icon-color: var(--goa-color-interactive-default, #0070c4);
  // --mdc-checkbox-selected-icon-color: var(--goa-color-interactive-default, #0070c4);
  // --mdc-checkbox-selected-pressed-icon-color: var(--goa-color-interactive-default, #0070c4);
  // --mdc-checkbox-unselected-focus-icon-color: #212121;
  // --mdc-checkbox-unselected-hover-icon-color: #212121;
  // --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  // --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  // --mdc-checkbox-selected-focus-state-layer-color: var(--goa-color-interactive-default, #0070c4);
  // --mdc-checkbox-selected-hover-state-layer-color: var(--goa-color-interactive-default, #0070c4);
  // --mdc-checkbox-selected-pressed-state-layer-color: var(--goa-color-interactive-default, #0070c4);
  // --mdc-checkbox-unselected-focus-state-layer-color: black;
  // --mdc-checkbox-unselected-hover-state-layer-color: black;
  // --mdc-checkbox-unselected-pressed-state-layer-color: black;

  --mdc-checkbox-unselected-focus-state-layer-color: transparent;
  --mdc-checkbox-selected-focus-state-layer-color: transparent;
  --mdc-checkbox-state-layer-size: 24px;
}

.mat-mdc-checkbox .mat-checkbox-ripple {
  display: none;
}

//style for material checkbox list
mat-selection-list.mat-mdc-selection-list {
  mat-list-option.mat-mdc-list-option {
    height: auto !important; //need this because it's pulling the min-height from dropdown list
    span {
      //checkbox label color is getting overridden by material. reset this back to goa default color
      color: var(--goa-color-text-default, #333333);
      white-space: inherit; //material is setting nowrap which can cause stuff to break in mobile. container should handle this.
    }

    //prevents checkbox to be vertically aligned in the middle when text wraps
    //material was setting this to center which looks fine for 1 liner, but on mobile when text wraps it does not look right
    .mdc-list-item__start {
      align-self: auto !important;
      div.mdc-checkbox {
        margin: 2px 0; //set the checkbox margins to the same as the label beside it so it lines up vertically.
      }
    }
  }
}
mat-selection-list.mat-mdc-list-base {
  //remove background color on checkbox lists
  --mdc-list-list-item-focus-state-layer-color: inherit;
  --mdc-list-list-item-focus-state-layer-opacity: inherit;
  --mdc-list-list-item-focus-label-text-color: inherit;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:focus:focus:not(:checked):not(:indeterminate)
  ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:focus:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:focus:indeterminate ~ .mdc-checkbox__background {
  @extend .outline;
}
