mat-datepicker-content mat-calendar * {
  font-size: 16px; //goa webcomponents sets it to 18 which causes it to spill
}
mat-datepicker-toggle {
  .mat-mdc-button-persistent-ripple {
    display: none;
  }
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover {
  --mat-datepicker-calendar-date-text-color: var(--goa-color-greyscale-white, #fff);
}
