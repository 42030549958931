$desktop-width: 1200px;
$tablet-width: 768px;
$mobile-width: 500px;

@mixin tablet {
  @media screen and (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}
