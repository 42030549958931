//@import url('https://p.typekit.net/p.css?s=1&k=nur5yta&ht=tk&f=26036.26037.26044.26046.26047&a=14638192&app=typekit&e=css');
.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

/* typekit ***/
/* This style is from the alberta design system */
// @font-face {
//   font-family: 'acumin-pro-semi-condensed';
//   src: url('https://use.typekit.net/af/3f7b4d/00000000000000003b9acb2d/27/l?subset_id=1&fvd=n4&v=3') format('woff2'),
//     url('https://use.typekit.net/af/3f7b4d/00000000000000003b9acb2d/27/d?subset_id=1&fvd=n4&v=3') format('woff'),
//     url('https://use.typekit.net/af/3f7b4d/00000000000000003b9acb2d/27/a?subset_id=1&fvd=n4&v=3') format('opentype');
//   font-style: normal;
//   font-weight: 400;
// }

// @font-face {
//   font-family: 'acumin-pro-semi-condensed';
//   src: url('https://use.typekit.net/af/761912/00000000000000003b9acb2e/27/l?subset_id=1&fvd=i4&v=3') format('woff2'),
//     url('https://use.typekit.net/af/761912/00000000000000003b9acb2e/27/d?subset_id=1&fvd=i4&v=3') format('woff'),
//     url('https://use.typekit.net/af/761912/00000000000000003b9acb2e/27/a?subset_id=1&fvd=i4&v=3') format('opentype');
//   font-style: italic;
//   font-weight: 400;
// }

// @font-face {
//   font-family: 'acumin-pro-semi-condensed';
//   src: url('https://use.typekit.net/af/ee7f3d/00000000000000003b9acb33/27/l?subset_id=1&fvd=n7&v=3') format('woff2'),
//     url('https://use.typekit.net/af/ee7f3d/00000000000000003b9acb33/27/d?subset_id=1&fvd=n7&v=3') format('woff'),
//     url('https://use.typekit.net/af/ee7f3d/00000000000000003b9acb33/27/a?subset_id=1&fvd=n7&v=3') format('opentype');
//   font-style: normal;
//   font-weight: 700;
// }

// @font-face {
//   font-family: 'acumin-pro-semi-condensed';
//   src: url('https://use.typekit.net/af/503f80/00000000000000003b9acb34/27/l?subset_id=1&fvd=i7&v=3') format('woff2'),
//     url('https://use.typekit.net/af/503f80/00000000000000003b9acb34/27/d?subset_id=1&fvd=i7&v=3') format('woff'),
//     url('https://use.typekit.net/af/503f80/00000000000000003b9acb34/27/a?subset_id=1&fvd=i7&v=3') format('opentype');
//   font-style: italic;
//   font-weight: 700;
// }

// @font-face {
//   font-family: 'acumin-pro-semi-condensed';
//   src: url('https://use.typekit.net/af/e60e87/00000000000000003b9acb31/27/l?subset_id=1&fvd=n6&v=3') format('woff2'),
//     url('https://use.typekit.net/af/e60e87/00000000000000003b9acb31/27/d?subset_id=1&fvd=n6&v=3') format('woff'),
//     url('https://use.typekit.net/af/e60e87/00000000000000003b9acb31/27/a?subset_id=1&fvd=n6&v=3') format('opentype');
//   font-style: normal;
//   font-weight: 600;
// }

/* This style is from the alberta design system */
body,
input,
button,
textarea {
  background: #fff;
  font-family: acumin-pro-semi-condensed, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

h1 {
  margin: 0;
  padding: 0;
  line-height: 56px;
  font-size: 48px;
}

h2 {
  margin: 24px 0 0;
  padding: 0;
  line-height: 44px;
  color: #333;
  font-size: 36px;
  font-weight: 400;
}

h3 {
  margin: 24px 0 0;
  padding: 0;
  line-height: 32px;
  color: #333;
  font-size: 24px;
  font-weight: 400;
}

h4 {
  margin: 23px 0 0;
  padding: 0;
  line-height: 28px;
  color: #333;
  font-size: 18px;
  font-weight: 700;
}

h5 {
  margin: 23px 0 0;
  padding: 0;
  line-height: 28px;
  color: #333;
  font-size: 18px;
  font-weight: 700;
}

h6 {
  margin: 23px 0 0;
  padding: 0;
  line-height: 28px;
  color: #333;
  font-size: 18px;
  font-weight: 700;
}

p {
  margin: 23px 0 0;
  font-size: 18px;
  line-height: 28px;
}

dt {
  font-weight: 700;
}

li {
  margin: 13px 0 0 28px;
  font-size: 18px;
  line-height: 28px;
}

ul {
  list-style-type: disc;
  margin-top: 28px;
}

ul ul {
  list-style-type: disc;
}

a:link {
  color: #0070c4;
}

a:visited {
  color: #756693;
}

a:hover {
  color: #004f84;
}

a:focus {
  outline-width: thin;
  outline-style: solid;
  outline-color: #004f84;
  outline-offset: 0px;
}

/* anchors *******************************************************************/
html:not([dir='rtl']) main a[target='_blank']:after {
  display: inline-block;
  width: 17px;
  height: 16px;
  margin: 0px 0px -2px 5px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 22 20.51'%3E%3Cpath fill='%23333' d='M21,19.69a.58.58,0,0,1-.57.57H3.57A.58.58,0,0,1,3,19.69V4.31a.58.58,0,0,1,.57-.57H6.82v-2H3.57A2.58,2.58,0,0,0,1,4.31V19.69a2.58,2.58,0,0,0,2.57,2.57H20.43A2.58,2.58,0,0,0,23,19.69V16.44H21Z' transform='translate(-1 -1.74)'/%3E%3Cpath fill='%23333' d='M22,1.75H14.15a1,1,0,1,0,0,2h5.44l-7.41,7.41a1,1,0,0,0,.71,1.71,1,1,0,0,0,.7-.3L21,5.17V10.6a1,1,0,1,0,2,0V2.75A1,1,0,0,0,22,1.75Z' transform='translate(-1 -1.74)'/%3E%3C/svg%3E")
    no-repeat center center;
  content: '';
}

a[href$='.pdf']:after,
a[href$='.doc']:after,
a[href$='.docx']:after {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0px 0px -3px 5px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' shape-rendering='geometricPrecision'%3E%3Cpath d='M0 16L16 16L16 14L0 14Z  M8 14L0 6L4 6L4 0L12 0L12 6L16 6Z' fill='%2336424a'/%3E%3C/svg%3E")
    no-repeat center center;
  content: '';
}
