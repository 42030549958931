$ads-mat-expansion-panel-header-color: #f1f1f1;
$ads-mat-expansion-panel-title-color: rgba(0, 0, 0, 0.54);
$ads-mat-expansion-panel-content-header-color: grey;
$ads-mat-expansion-panel-border-color: black;
$ads-mat-expansion-panel-content-color: black;

mat-expansion-panel.mat-expanded {
  mat-expansion-panel-header.mat-expanded:hover,
  mat-expansion-panel-header.mat-expanded {
    background-color: $ads-mat-expansion-panel-header-color;
  }
  mat-expansion-panel-header {
    height: auto;
    min-height: 50px;
    mat-panel-description {
      color: $ads-mat-expansion-panel-content-color;
    }
  }
}

.mat-expansion-panel {
  box-shadow: none;
  --mat-expansion-header-collapsed-state-height: auto;
  --mat-expansion-header-expanded-state-height: auto;
  --mat-expansion-header-text-size: 18px;
  --mat-expansion-header-container-background-color: var(--goa-color-greyscale-100, #f1f1f1);
  --mat-expansion-container-background-color: var(--goa-color-greyscale-white, #fff);

  &.muted-expansion-panel {
    &:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }
    --mat-expansion-header-text-color: var(--goa-color-interactive-default, #0070c4);

    border: 1px solid var(--goa-color-greyscale-200, #dcdcdc);
    border-radius: 0;
    box-shadow: none;

    :not([class*='mat-expanded']) {
      border-bottom: 0;
    }

    mat-expansion-panel-header {
      border-bottom: 1px solid var(--goa-color-greyscale-200, #dcdcdc);
      --mat-expansion-header-container-background-color: var(--goa-color-greyscale-white, #fff);
    }
    mat-panel-title {
      text-decoration: underline;
    }
  }

  &.mat-expanded {
    border: 1px solid var(--goa-color-greyscale-700, #666);
  }

  .mat-expansion-panel-header {
    padding: 0 24px 0 24px;
    min-height: 50px;

    font-weight: normal;

    &:focus {
      background-color: var(--goa-color-greyscale-100, #f1f1f1);
    }

    &:hover {
      background-color: #fafafa !important;
    }
  }

  .mat-expansion-panel-content {
    font: unset;
  }
}

//header style for not expanded
.mat-expansion-panel:not([class*='mat-expanded']) {
  mat-expansion-panel-header {
    mat-panel-description {
      color: inherit;
    }
  }
}
